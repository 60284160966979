import { MouseEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { Checkbox, Intent, Menu } from "@blueprintjs/core";
import { ItemListRendererProps, ItemPredicate, ItemRenderer, MultiSelect, Select } from "@blueprintjs/select";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import {
  MicrosoftTeam,
  MicrosoftTeamsChannel,
  MicrosoftTeamsEventTypes,
  MicrosoftTeamsLinkChannelDto,
  MicrosoftTeamsLinkedTeam,
  supportMsTeamsEventTypes,
} from "@rollup-api/models";

import styles from "./MicrosoftTeamsSettingsAddNewLink.module.scss";

type Props = {
  link?: MicrosoftTeamsLinkChannelDto;
  teams: MicrosoftTeam[];
  loading?: boolean;
  className?: string;
  subscriptions: MicrosoftTeamsLinkedTeam[];
  onCancel: () => void;
  onSave: (channelLink: MicrosoftTeamsLinkChannelDto) => void;
};

const MicrosoftTeamsSettingsAddNewLink = (props: Props) => {
  const { onCancel, subscriptions, teams, link, loading, onSave, className } = props;
  const [channel, setChannel] = useState<MicrosoftTeamsChannel>();
  const [team, setTeam] = useState<MicrosoftTeam>();
  const [entityTypes, setEntityTypes] = useState<MicrosoftTeamsEventTypes[]>([]);

  useEffect(() => {
    if (!link) {
      setChannel(undefined);
    } else {
      const teamChannels = teams.find(t => t.teamId === link.teamId)?.channels || [];
      setChannel(teamChannels.find(c => c.id === link.channelId));
    }
  }, [link, team, teams]);

  useEffect(() => {
    setEntityTypes(link?.entityTypes || []);
    setTeam(teams.find(t => t.teamId === link?.teamId));
  }, [link, teams]);

  const filteredChannelList = useMemo(() => {
    if (!team) {
      return [];
    }

    const subscribedChannels = subscriptions.find(t => t.teamId === team.teamId)?.channels || [];
    return team.channels.filter(item => !subscribedChannels.find(c => c.id === item.id)).sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [team, subscriptions]);

  const handleSelectEvent = (entityType: MicrosoftTeamsEventTypes) => {
    if (entityTypes.includes(entityType)) {
      setEntityTypes(entityTypes.filter(item => item !== entityType));
    } else {
      setEntityTypes([...entityTypes, entityType]);
    }
  };

  const handleSelectAll = (e: MouseEvent) => {
    e.stopPropagation();
    setEntityTypes(supportMsTeamsEventTypes);
  };

  const handleDeselectAll = (e: MouseEvent) => {
    e.stopPropagation();
    setEntityTypes([]);
  };

  const handleSave = () => {
    if (channel && entityTypes.length && team) {
      onSave({ channelId: channel.id, teamId: team.teamId, entityTypes });
    }
  };

  const handleRenderTeam: ItemRenderer<MicrosoftTeam> = (item: MicrosoftTeam, { modifiers, handleClick }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        onClick={handleClick}
        active={item.teamId === team?.teamId}
        e2eIdentifiers="menu-item"
        key={item.teamId}
        roleStructure="listoption"
        text={item.teamName}
      />
    );
  };

  const handleRenderItem: ItemRenderer<MicrosoftTeamsChannel> = (
    MicrosoftTeamsChannel: MicrosoftTeamsChannel,
    { modifiers, handleClick }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        onClick={handleClick}
        active={MicrosoftTeamsChannel.id === channel?.id}
        e2eIdentifiers="menu-item"
        key={MicrosoftTeamsChannel.id}
        roleStructure="listoption"
        text={`#${MicrosoftTeamsChannel.name}`}
      />
    );
  };

  const handleRenderEventItem: ItemRenderer<MicrosoftTeamsEventTypes> = (item: MicrosoftTeamsEventTypes, { handleClick }) => (
    <MenuItem
      key={item}
      e2eIdentifiers="select-event"
      text={item}
      onClick={handleClick}
      leftElement={
        <Checkbox
          className={styles.microsoftTeamsSettingsAddNewLinkMenuItemCheckbox}
          onClick={handleClick}
          checked={entityTypes.includes(item)}
        />
      }
    />
  );

  const renderRenderEventList = (params: ItemListRendererProps<MicrosoftTeamsEventTypes>) => {
    const { renderItem } = params;
    const areAllSelected = entityTypes.length === supportMsTeamsEventTypes.length;
    const isNoneSelected = entityTypes.length === 0;

    return (
      <Menu>
        <MenuItem
          e2eIdentifiers="select-all"
          text={areAllSelected ? "Deselect all" : "Select all"}
          onClick={areAllSelected ? handleDeselectAll : handleSelectAll}
          leftElement={
            <Checkbox
              className={styles.microsoftTeamsSettingsAddNewLinkMenuItemCheckbox}
              indeterminate={!areAllSelected && !isNoneSelected}
              onClick={areAllSelected ? handleDeselectAll : handleSelectAll}
              checked={entityTypes.length === supportMsTeamsEventTypes.length}
            />
          }
        />
        {params.items.map(renderItem)}
      </Menu>
    );
  };

  const handleRenderTag = (type: MicrosoftTeamsEventTypes) => type;

  const handleTagRemove = (_node: ReactNode, index: number) => {
    setEntityTypes(entityTypes.filter((_item, i) => i !== index));
  };

  const filterTeams: ItemPredicate<MicrosoftTeam> = (query, item: MicrosoftTeam, _index, exactMatch) => {
    const normalizedTitle = item.teamName.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  const filterItems: ItemPredicate<MicrosoftTeamsChannel> = (query, item: MicrosoftTeamsChannel, _index, exactMatch) => {
    const normalizedTitle = item.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedTitle === normalizedQuery;
    } else {
      return normalizedTitle.indexOf(normalizedQuery) >= 0;
    }
  };

  const filterTypes = (query: string, item: MicrosoftTeamsEventTypes) => item.toLowerCase().includes(query.toLowerCase());

  return (
    <div className={classNames(styles.microsoftTeamsSettingsAddNewLink, className)}>
      <Select<MicrosoftTeam>
        onItemSelect={setTeam}
        itemPredicate={filterTeams}
        className={styles.microsoftTeamsSettingsAddNewLinkSelect}
        disabled={loading}
        noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results" roleStructure="listoption" />}
        items={teams}
        popoverProps={{ minimal: true, popoverClassName: styles.microsoftTeamsSettingsAddNewLinkSelectPopover }}
        itemRenderer={handleRenderTeam}
      >
        <Button
          disabled={!!link}
          alignText="left"
          fill
          e2eIdentifiers="button"
          text={team ? team.teamName : "Select team"}
          rightIcon="double-caret-vertical"
        />
      </Select>
      <Select<MicrosoftTeamsChannel>
        onItemSelect={setChannel}
        itemPredicate={filterItems}
        className={styles.microsoftTeamsSettingsAddNewLinkSelect}
        disabled={loading}
        noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results" roleStructure="listoption" />}
        items={filteredChannelList}
        popoverProps={{ minimal: true, popoverClassName: styles.microsoftTeamsSettingsAddNewLinkSelectPopover }}
        itemRenderer={handleRenderItem}
      >
        <Button
          disabled={!!link}
          alignText="left"
          fill
          e2eIdentifiers="button"
          text={channel ? `#${channel.name}` : "Select channel"}
          rightIcon="double-caret-vertical"
        />
      </Select>
      <MultiSelect<MicrosoftTeamsEventTypes>
        fill
        className={classNames(styles.microsoftTeamsSettingsAddNewLinkSelect, styles.microsoftTeamsSettingsAddNewLinkEventSelect)}
        onItemSelect={handleSelectEvent}
        items={supportMsTeamsEventTypes}
        itemPredicate={filterTypes}
        noResults={<MenuItem e2eIdentifiers="no-results" disabled text="No results." roleStructure="listoption" />}
        popoverProps={{ minimal: true, matchTargetWidth: true }}
        itemRenderer={handleRenderEventItem}
        itemListRenderer={renderRenderEventList}
        selectedItems={entityTypes}
        tagRenderer={handleRenderTag}
        tagInputProps={{
          placeholder: "Select events",
          tagProps: { className: styles.microsoftTeamsSettingsAddNewLinkTag },
          onRemove: handleTagRemove,
        }}
      />
      <Button
        minimal
        outlined
        disabled={loading}
        className={styles.microsoftTeamsSettingsAddNewLinkBtn}
        onClick={onCancel}
        e2eIdentifiers="cancel"
        text="Cancel"
      />
      <Button
        loading={loading}
        className={styles.microsoftTeamsSettingsAddNewLinkBtn}
        onClick={handleSave}
        disabled={!channel || !entityTypes.length}
        e2eIdentifiers="save"
        text="Save"
        intent={Intent.PRIMARY}
      />
    </div>
  );
};

export default observer(MicrosoftTeamsSettingsAddNewLink);
