import { RefObject } from "react";
import { CustomCellRendererProps } from "ag-grid-react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import StatusInstanceLinkCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/StatusInstanceLinkCell";
import StatusInstanceSelectCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/StatusInstanceSelectCell";
import UserSelectCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/UserSelectCell";
import StatusTypeCheckEditor from "@components/ProjectManagement/StatusTypeCheckEditor";
import StatusTypeDateEditor from "@components/ProjectManagement/StatusTypeDateEditor";
import StatusTypeNumberEditor from "@components/ProjectManagement/StatusTypeNumberEditor";
import StatusTypeTextEditor from "@components/ProjectManagement/StatusTypeTextEditor";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import styles from "./RequirementsStatusCell.module.scss";
export interface IRequirementsStatusCellOwnProps {
  statusDefinition: IStatusDefinition;
  containerRef?: RefObject<HTMLDivElement>;
}

interface IBlockStatusCellProps extends IRequirementsStatusCellOwnProps, CustomCellRendererProps<IRequirementBlock> {}

const typesWithHoverStyle = [StatusType.singleSelect, StatusType.multiSelect];

const RequirementsStatusCell = (props: IBlockStatusCellProps) => {
  const { statusDefinition, data: requirementBlock, containerRef } = props;

  if (!requirementBlock || !isAlive(requirementBlock)) {
    return null;
  }

  const statusDefinitionProxy = appStore.workspaceModel?.statusDefinitions.find(i => i.id === statusDefinition?.id);

  if (!statusDefinitionProxy) {
    return null;
  }

  const statusInstance = requirementBlock.statusInstances.find(i => i.statusDefinition.id === statusDefinition.id);

  const handleOnAddStatusInstance = async (statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined> => {
    if (appStore.workspaceModel) {
      return appStore.workspaceModel.addRequirementStatusInstance({ parentRequirement: requirementBlock, statusDefinition, value });
    }
  };

  const handleDeleteStatusInstance = (statusInstance: IStatusInstance) => {
    requirementBlock.deleteStatusInstance(statusInstance);
    appStore.workspaceModel?.deleteStatusInstance(statusInstance);
  };

  const editorProps = {
    statusDefinition: statusDefinitionProxy,
    statusInstance,
    disabled: requirementBlock.locked,
    onAddStatusInstance: handleOnAddStatusInstance,
    onDeleteStatusInstance: handleDeleteStatusInstance,
  };
  const { type } = statusDefinitionProxy;

  const renderComponent = () => {
    switch (type) {
      case StatusType.mention:
        return <UserSelectCell {...editorProps} />;
      case StatusType.multiSelect:
      case StatusType.singleSelect: {
        return (
          <StatusInstanceSelectCell
            portalContainer={containerRef?.current ?? undefined}
            containerClassName={styles.requirementsStatusCellSelect}
            gridApi={props.api}
            {...editorProps}
          />
        );
      }
      case StatusType.number:
        return <StatusTypeNumberEditor hidePlaceholder {...editorProps} />;
      case StatusType.date:
        return <StatusTypeDateEditor hidePlaceholder {...editorProps} />;
      case StatusType.check:
        return <StatusTypeCheckEditor {...editorProps} />;
      case StatusType.url:
        return <StatusInstanceLinkCell {...editorProps} />;
      default:
        return <StatusTypeTextEditor hidePlaceholder {...editorProps} />;
    }
  };

  return (
    <div
      className={classNames(styles.requirementsStatusCell, {
        [styles.requirementsStatusCellHoverStyle]: typesWithHoverStyle.includes(type),
      })}
      data-testid={`${requirementBlock.label}_${editorProps.statusDefinition.label}_${editorProps.statusDefinition.id}`}
    >
      {renderComponent()}
    </div>
  );
};

export default observer(RequirementsStatusCell);
