import { PropertyDataType } from "@rollup-io/engineering";
import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { BomColumnType, BomMetaColumn, UpdateBomColumnDto } from "@rollup-api/models/bom";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { getBomColumnLabelFromMetaColumn, getSubtotalColumnValue } from "@utilities/Bom";
import { rollupClient } from "src/core/api";

import appStore from "../AppStore";
import { IBlock } from "../BlockStore";
import { IPropertyDefinition, PropertyDefinitionStore } from "../PropertyDefinitionStore";
import { IPropertyInstance } from "../PropertyInstanceStore";
import { IStatusDefinition, StatusDefinitionStore } from "../StatusDefinitionStore";

import { BomTableStore } from "./BomTableStore";

const NUMERIC_META_COLUMNS = [BomMetaColumn.Level, BomMetaColumn.Multiplicity, BomMetaColumn.Subtotal];

export const BomColumnStore = types
  .model("BomColumn", {
    id: types.identifier,
    metaColumn: types.maybeNull(types.enumeration(Object.values(BomMetaColumn))),
    propertyDefinition: types.maybeNull(types.safeReference(PropertyDefinitionStore)),
    statusDefinition: types.maybeNull(types.safeReference(StatusDefinitionStore)),
    table: types.safeReference(types.late((): IAnyModelType => BomTableStore)),
    visible: types.optional(types.boolean, true),
    width: types.optional(types.number, 0),
    orderIndex: types.optional(types.number, 0),
    rowGroup: types.optional(types.boolean, false),
  })
  .actions(self => ({
    patch(dto: UpdateBomColumnDto) {
      // Prevent updating of fixed properties
      const invalidFields = ["id", "propertyDefinition", "statusDefinition", "table"];
      const updateKeys = Object.keys(dto);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, dto);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    propertyValueChange(value: string, block: IBlock, propertyDefinition: IPropertyDefinition) {
      const propertyInstance: IPropertyInstance | undefined = block.propertyInstances?.find(
        p => p.propertyDefinition === self.propertyDefinition
      );
      if (propertyInstance) {
        if (!value) {
          appStore.workspaceModel?.deletePropertyInstance(propertyInstance);
        } else if (propertyDefinition.dataType === PropertyDataType.string) {
          propertyInstance.setStringValue(value);
        } else {
          propertyInstance.setScalarValueFromString(value);
        }
      } else {
        appStore.workspaceModel?.addPropertyInstance(block, propertyDefinition, undefined, value);
      }
    },
    statusValueChange(value: string, block: IBlock, statusDefinition: IStatusDefinition) {
      const statusInstance = block.statusInstances?.find(s => s.statusDefinition === statusDefinition);
      if (statusInstance) {
        if (value) {
          statusInstance.setValue(value);
        } else {
          appStore.workspaceModel?.deleteStatusInstance(statusInstance);
        }
      } else {
        appStore.workspaceModel?.addStatusInstance(block, statusDefinition, value);
      }
    },
    setWidth(width: number, notify = true) {
      self.width = width;

      if (notify) {
        rollupClient.bomTables.updateColumn(self.table.id, self.id, { width });
      }
    },
    metaValueChange(value: string, block: IBlock) {
      switch (self.metaColumn) {
        case BomMetaColumn.Name:
          block.setLabel(value);
          break;
        case BomMetaColumn.Notes:
          block.setNewDescription(value);
          break;
        case BomMetaColumn.PartNumber:
          block.updatePartNumber(value);
          break;
      }
    },
    setOrderIndex(value: number) {
      self.orderIndex = value;
    },
    setRowGroup(rowGroup: boolean, notify = true) {
      self.rowGroup = rowGroup;

      if (notify) {
        rollupClient.bomTables.updateColumn(self.table.id, self.id, { rowGroup });
      }
    },
  }))
  .views(self => ({
    get isNumeric() {
      if (self.propertyDefinition) {
        return true;
      } else if (self.metaColumn) {
        return NUMERIC_META_COLUMNS.includes(self.metaColumn);
      } else {
        return false;
      }
    },
    get columnType() {
      if (self.propertyDefinition) {
        return BomColumnType.Property;
      } else if (self.statusDefinition) {
        return BomColumnType.Status;
      } else if (self.metaColumn) {
        return BomColumnType.Meta;
      }
      return BomColumnType.Unknown;
    },
    get lockPosition(): "right" | undefined {
      if (self.metaColumn === BomMetaColumn.Subtotal) {
        return "right";
      }
    },
    get suppressMovable(): boolean {
      return self.metaColumn === BomMetaColumn.Subtotal;
    },
    get label(): string {
      if (self.propertyDefinition) {
        return self.propertyDefinition.label;
      } else if (self.statusDefinition) {
        return self.statusDefinition.label;
      } else if (self.metaColumn) {
        return getBomColumnLabelFromMetaColumn(self.metaColumn);
      }
      return "Unknown";
    },
    getValue(catalogItem: ICatalogItem) {
      if (self.metaColumn) {
        switch (self.metaColumn) {
          case BomMetaColumn.Id:
            return catalogItem.id;
          case BomMetaColumn.Name:
            return catalogItem.name;
          case BomMetaColumn.File:
            return catalogItem.latestVersion?.attachment?.name || "";
          case BomMetaColumn.Notes:
            return catalogItem.description;
          case BomMetaColumn.Thumbnail:
            return catalogItem.imageUrl;
          case BomMetaColumn.PartNumber:
            return catalogItem.partNumber;
          case BomMetaColumn.ProductGroup:
            return catalogItem.parentItem?.name || "";
          case BomMetaColumn.Subtotal:
            return getSubtotalColumnValue(catalogItem);
          case BomMetaColumn.Level:
            return catalogItem.pathIds.length - 1 || "";
        }
      }

      return "";
    },
    setValue(value: string, block: IBlock) {
      if (self.propertyDefinition) {
        self.propertyValueChange(value, block, self.propertyDefinition);
      } else if (self.statusDefinition) {
        self.statusValueChange(value, block, self.statusDefinition);
      } else if (self.metaColumn) {
        self.metaValueChange(value, block);
      }
    },
  }));

export interface IBomColumn extends Instance<typeof BomColumnStore> {}
interface IBomColumnSnapshotIn extends SnapshotIn<typeof BomColumnStore> {}
interface IBomColumnSnapshotOut extends SnapshotOut<typeof BomColumnStore> {}
export interface IBomColumnMobxType extends IType<IBomColumnSnapshotIn, IBomColumnSnapshotOut, IBomColumn> {}

export function subscribeToBomColumnEvents(socket: Socket) {
  socket.on("updateBomColumn", (data: { tableId: string; workspaceId: string; id: string; updateBomColumnDto: UpdateBomColumnDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id && appStore.env.activeBomTableId === data.tableId) {
      const bomTable = appStore.workspaceModel.bomTablesMap.get(data.tableId);
      const bomColumn = bomTable?.columnMap.get(data.id);
      if (bomTable?.tableGridApi) {
        if (data.updateBomColumnDto.width) {
          bomTable.tableGridApi.setColumnWidth(data.id, data.updateBomColumnDto.width);
        }
        if (data.updateBomColumnDto.visible !== undefined) {
          bomTable.tableGridApi.setColumnsVisible([data.id], data.updateBomColumnDto.visible);
        }
      }
      bomColumn?.patch(data.updateBomColumnDto);
    }
  });
}
