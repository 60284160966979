import { Text, TextVariant } from "@ui/Text";

import { Button } from "@components/Button";

import "./UserSelectorTargetButton.scss";

export interface UserSelectorTargetButtonProps {
  onClick?: () => void;
}

const UserSelectorTargetButton = ({ onClick }: UserSelectorTargetButtonProps) => {
  return (
    <Button minimal icon="add" className="user-selector-target-button" onClick={onClick} e2eIdentifiers="user-selector-target-button">
      <Text variant={TextVariant.Button2Underline}>Add user</Text>
    </Button>
  );
};

export default UserSelectorTargetButton;
