import { useEffect, useRef, useState } from "react";
import { Intent, Menu } from "@blueprintjs/core";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { MenuItem } from "@components/MenuItem";
import { Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { getPluralOrSingularString } from "@utilities";

import { TCellRendererProps } from "../types";

import "./ActionsCell.scss";

const ActionsCell = (props: TCellRendererProps) => {
  const { registerRowDragger } = props;
  const [isOpen, setIsOpen] = useState(false);
  const catalogItem = props.data?.catalogItem;
  const buttonRef = useRef<HTMLButtonElement>(null);
  const workspace = useWorkspace();
  const activeTable = workspace.bomTablesMap.get(appStore.env.activeBomTableId || "");

  useEffect(() => {
    buttonRef.current && registerRowDragger(buttonRef.current, undefined, undefined, true);
  }, [registerRowDragger, buttonRef]);

  if (!activeTable || !catalogItem) {
    return null;
  }

  const handleRemoveRow = () => {
    if (activeTable.previewItemId === catalogItem.id) {
      activeTable.setPreviewItemId();
    }
    if (activeTable.selectedRows.includes(catalogItem.id)) {
      activeTable.removeSelectedRows();
    } else {
      activeTable.removeRow(catalogItem.id);
    }
  };

  const rowString = activeTable.selectedRows.includes(catalogItem.id)
    ? getPluralOrSingularString(activeTable.selectedRows.length, "row")
    : "row";

  const getActionsMenu = () => (
    <Menu>
      <MenuItem icon="minus" text={`Remove ${rowString}`} onClick={handleRemoveRow} e2eIdentifiers="remove-row" />
    </Menu>
  );

  // hack to prevent row selection on actions cell click
  // https://stackoverflow.com/a/63968681
  const handleRefEvents = (ref: HTMLDivElement | null) => {
    if (ref) {
      ref.onclick = (e: MouseEvent) => {
        setIsOpen(!isOpen);
        e.stopPropagation();
      };
    }
  };

  return (
    <div ref={handleRefEvents} className="actions-cell">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} canEscapeKeyClose placement="bottom-start" content={getActionsMenu()}>
        <Button
          buttonRef={buttonRef}
          small
          minimal
          icon="drag-handle-vertical"
          intent={Intent.NONE}
          e2eIdentifiers={["menuexpand", catalogItem.id, catalogItem.name]}
        />
      </Popover>
    </div>
  );
};

export default observer(ActionsCell);
