import { useMemo, useRef } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import useHiddenCellElements from "@components/Modeling/ModelingFrame/Table/hooks";
import { Popover } from "@components/Popover";
import UserSelectorMenu from "@components/SelectorMenus/UserSelector";
import UserInfo from "@components/UserInfo/UserInfo";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import styles from "./UserSelectCell.module.scss";

type UserSelectCellProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  disabled?: boolean;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

const UserSelectCell = (props: UserSelectCellProps) => {
  const { statusDefinition, statusInstance, disabled, onAddStatusInstance, onDeleteStatusInstance } = props;
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemsResultRef = useRef<HTMLDivElement>(null);
  const items = useMemo(() => statusInstance?.arrayValue.map(id => ({ id })) || [], [statusInstance?.arrayValue]);
  const { hiddenElements, singleItemVisibleLeft } = useHiddenCellElements({
    items,
    itemsContainerRef,
    itemsResultRef,
  });

  const handleChange = async (selectedUserIds?: string[]) => {
    if (!statusInstance) {
      await onAddStatusInstance(statusDefinition, JSON.stringify(selectedUserIds));
    } else {
      if (!selectedUserIds) {
        onDeleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(JSON.stringify(selectedUserIds));
      }
    }
  };

  return (
    <Popover
      targetTagName="div"
      targetProps={{ className: styles.userSelectCellPopover }}
      content={<UserSelectorMenu maxHeight={400} onChange={handleChange} selectedUserIds={statusInstance?.arrayValue} />}
      position="bottom"
      disabled={disabled}
    >
      <div
        ref={itemsContainerRef}
        data-testid="userSelectCell"
        className={classNames(styles.userSelectCell, { [styles.disabled]: disabled })}
      >
        <div className={styles.userSelectCellResult}>
          {statusInstance?.arrayValue.map(id => {
            return hiddenElements.includes(id) ? null : (
              <UserInfo
                className={classNames({ [styles.userSelectCellSingleItem]: singleItemVisibleLeft })}
                key={id}
                size="extra-small"
                userId={id}
              />
            );
          })}
          {hiddenElements.length > 0 && <div className={styles.userSelectCellMore}>...</div>}
        </div>
        {/* we always render second hidden row to calculate width of the whole row correctly */}
        <div ref={itemsResultRef} className={styles.userSelectCellHiddenResult}>
          {statusInstance?.arrayValue.map(id => <UserInfo key={id} size="extra-small" userId={id} />)}
        </div>
      </div>
    </Popover>
  );
};

export default observer(UserSelectCell);
