import { isUnitsBaseEqual, PropertyDataType } from "@rollup-io/engineering";
import {
  CellClassParams,
  CellFocusedEvent,
  Column,
  GetRowIdParams,
  GridApi,
  ICellRendererParams,
  RowDragEndEvent,
  RowDragEvent,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import { isAlive } from "mobx-state-tree";

import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IPropertyInstance } from "@store/PropertyInstanceStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { formatSmartExpression, formatStringFromMathResult, getFormattedDate, isCurrency, isStringArraysEqual } from "@utilities";
import { currencyCellValueFormatter } from "@utilities/AgGridUtils";

import { IHeaderCellParams } from "./Cells/HeaderCell";
import { TStateColumn } from "./Components/ColumnTogglePanel";
import { CELL_MOCK, DEFAULT_SORT_INDEX } from "./constants";
import { NodeInfo, TConfigDataObjects } from "./types";

export const getElementPosition = (el: any) => {
  const rect = el.getBoundingClientRect();
  const x = rect.left + window.scrollX;
  const y = rect.top + window.scrollY;

  return { x, y };
};

export const passStatusToRibbon = (status: IStatusInstance | undefined) => {
  const type = status?.statusDefinition?.type;
  return type !== StatusType.mention && type !== StatusType.singleSelect && type !== StatusType.multiSelect;
};

export const getCellProperty = (getterProps: { column: Column; data: NodeInfo | undefined }): IPropertyInstance | undefined => {
  if (getterProps.data?.block?.propertyInstances?.length) {
    const colId = getterProps.column.getColId();
    return getterProps.data?.block.propertyInstances.find(p => p?.propertyDefinition?.id === colId);
  }

  return undefined;
};

export const getNodesPath = (event: RowDragEvent<{ path: string[] }>): { root: Array<string>; target: Array<string> } => {
  const nodePath = [...(event.node.data?.path || [])];
  const targetNodePath = [...(event.overNode?.data?.path || [])];

  return {
    root: nodePath,
    target: targetNodePath,
  };
};

export const getMoveDestinationBlock = (source: IBlock, target: IBlock): IBlock => {
  const { children } = source.parentBlock;
  const sourceBlockIndex = children.indexOf(source);
  const targetBlockIndex = children.indexOf(target);

  if (targetBlockIndex < sourceBlockIndex) {
    return children.at(targetBlockIndex + 1);
  }

  return target;
};

export const statusCellValueSetter = (cellParams: ValueSetterParams<NodeInfo>, statusDefinition: IStatusDefinition) => {
  const statusInstance = cellParams.data.block?.validatedStatusInstances.find(i => i.statusDefinition?.id === statusDefinition.id);

  if (statusInstance) {
    statusInstance.setValue(cellParams.newValue);
  } else if (cellParams.data?.block) {
    appStore.workspaceModel?.addStatusInstance(cellParams.data?.block, statusDefinition, cellParams.newValue);
  }

  return true;
};

export const cellValueSetter = (cellParams: ValueSetterParams<NodeInfo>) => {
  const cell = getCellProperty(cellParams);
  const propertyDefinition = (cellParams.colDef.headerComponentParams as IHeaderCellParams).propertyDefinition;
  const propertyDefinitionProxy = appStore.workspaceModel?.propertyDefinitionMap.get(propertyDefinition?.id || "");
  const cellProperty = cell?.propertyDefinition;

  if (cell && cellProperty) {
    if (cellProperty.dataType === PropertyDataType.scalar) {
      cell.setScalarValueFromString(cellParams.newValue);
    } else if (cellProperty.dataType === PropertyDataType.string) {
      cell.setStringValue(cellParams.newValue);
    }
  } else if (propertyDefinitionProxy && cellParams.data.block) {
    appStore.workspaceModel?.addPropertyInstance(cellParams.data.block, propertyDefinitionProxy, undefined, cellParams.newValue);
  }

  if (!cellParams.newValue && cell) {
    appStore.workspaceModel?.deletePropertyInstance(cell);
  }

  return true;
};

export const getStringValue = (
  cellProps: ValueGetterParams<NodeInfo> | ValueFormatterParams<NodeInfo>,
  propertyDefinition?: IPropertyDefinition
) => {
  const cell = getCellProperty(cellProps);
  const cellProperty = cell?.propertyDefinition;

  if (propertyDefinition) {
    const propertyInstance = cellProps.data?.block?.propertyInstances?.find(i => i?.propertyDefinition?.id === propertyDefinition?.id);

    if (propertyInstance) {
      if (isCurrency(propertyDefinition.unit)) {
        return currencyCellValueFormatter(propertyDefinition.unit, propertyInstance.numericValue?.toString() || "");
      }

      return propertyDefinition.dataType === PropertyDataType.scalar
        ? formatStringFromMathResult(propertyInstance.combinedResult, propertyDefinition.formatType)
        : propertyInstance.value;
    }
  }

  if (cell && cellProperty) {
    if (isCurrency(cellProperty.unit)) {
      return currencyCellValueFormatter(cellProperty.unit, cell.numericValue?.toString() || "");
    }

    return cellProperty.dataType === PropertyDataType.scalar
      ? formatStringFromMathResult(cell.combinedResult, undefined, cellProperty.unit)
      : cell.value;
  }

  return "";
};

export const statusCellValueGetter = (
  cellProps: ValueGetterParams<NodeInfo> | ICellRendererParams<NodeInfo>,
  statusDefinition: IStatusDefinition
) => {
  const statusInstance = cellProps.data?.block?.statusInstances?.find(i => i?.statusDefinition?.id === statusDefinition.id);

  if (statusInstance && statusInstance.statusDefinition) {
    return statusInstance.value;
  }

  switch (statusDefinition.type) {
    case StatusType.date:
    case StatusType.mention:
    case StatusType.singleSelect:
    case StatusType.multiSelect:
    case StatusType.check:
      return CELL_MOCK;
    default:
      return "";
  }
};

export const cellValueGetter = (cellProps: ValueGetterParams<NodeInfo>) => {
  const propertyInstance = getCellProperty(cellProps);

  if (propertyInstance) {
    if (propertyInstance.propertyDefinition?.dataType === PropertyDataType.scalar) {
      return formatSmartExpression(propertyInstance?.value, true) || "";
    } else {
      return propertyInstance.value;
    }
  } else {
    return "";
  }
};

export const multiplicityValueFormatter = (params: ValueFormatterParams<NodeInfo>) => {
  const block = params.data?.block;
  const isRootBlock = block === appStore.workspaceModel?.rootBlock;

  if (!block || !block.multiplicity || isRootBlock) {
    return "";
  }

  return block.multiplicity;
};

export const statusCellValueFormatter = (statusInstance: IStatusInstance) => {
  switch (statusInstance.statusDefinition?.type) {
    case StatusType.mention:
      return statusInstance.userValues.map(o => o.displayName).join(", ");
    case StatusType.singleSelect:
    case StatusType.multiSelect:
      return statusInstance.multiSelectValues.map(o => o.label).join(", ");
    case StatusType.date:
      return statusInstance.value ? getFormattedDate(new Date(statusInstance.value)) : "";
    case StatusType.url:
      return statusInstance.urlValue?.url || "";
    case StatusType.check:
      return statusInstance.checkedValue ? "true" : "false";
    default:
      return statusInstance.value || "";
  }
};

export const statusCellClassName = (_cellProps: CellClassParams<NodeInfo>, statusDefinition: IStatusDefinition) => {
  return `ag-status-cell--${statusDefinition.type}`;
};

export const propertyCellClassName = (cellProps: CellClassParams<NodeInfo>, propertyDefinition: IPropertyDefinition) => {
  const propertyInstance = getCellProperty(cellProps);
  if (propertyInstance) {
    if (propertyInstance.unit && !isUnitsBaseEqual(propertyInstance.unit, propertyDefinition.unit)) {
      return "ag-property-cell--warning ag-right-aligned-cell";
    }
  }

  return "ag-right-aligned-cell";
};

export const isValidRowMove = (event: RowDragEndEvent, srcId?: string, targetId?: string) => {
  const path = getNodesPath(event);
  const moveToChild = isStringArraysEqual(path.root, path.target.slice(0, path.root.length));
  const moveToSelf = srcId && targetId ? srcId === targetId : isStringArraysEqual(path.root, path.target);

  return !moveToChild && !moveToSelf;
};

export const getTableRowById = (id = "") => document.querySelector(`[row-id='${id}']`);

export const getDataPath = (data: NodeInfo) => data.path;

export const getJsonActualColumnsConfig = (columns: TStateColumn[]) => {
  const columnsConfig: TConfigDataObjects = columns.map((c: TStateColumn, i: number) => ({
    colId: c.id,
    sortIndex: i + DEFAULT_SORT_INDEX,
    hide: c.hide,
    width: c.width,
  }));

  return columnsConfig;
};

export const getStringActualColumnsConfig = (columns: TStateColumn[]): string => {
  const data = getJsonActualColumnsConfig(columns);
  return JSON.stringify({ data });
};

export const getRowId = (row: GetRowIdParams<NodeInfo>) => {
  return row.data.block && isAlive(row.data.block) ? `table-view-${row.data.block?.id}` : "";
};

export const handleCellFocused = (event: CellFocusedEvent<NodeInfo>) => {
  const focusedCell = event.api.getFocusedCell();
  let statusInstanceId = "";
  let propertyInstanceId = "";

  if (focusedCell) {
    const headerCellParams: IHeaderCellParams | undefined = focusedCell.column.getUserProvidedColDef()?.headerComponentParams;
    const propertyDefinition = headerCellParams?.propertyDefinition;
    const statusDefinition = headerCellParams?.statusDefinition;
    const rowNode = event.api.getDisplayedRowAtIndex(focusedCell.rowIndex);

    if (propertyDefinition) {
      const instance = getCellProperty({ column: focusedCell.column, data: rowNode?.data });
      propertyInstanceId = instance?.id || "";
    } else if (statusDefinition) {
      const instance = rowNode?.data?.block?.getStatusInstanceByStatusDefinitionId(statusDefinition.id);
      if (passStatusToRibbon(instance)) {
        statusInstanceId = instance?.id || "";
      }
    }
  }

  appStore.env?.tableView.setActivePropertyInstance(propertyInstanceId);
  appStore.env?.tableView.setActiveStatusInstance(statusInstanceId);
};

export const parseGridColumnsToState = (api: GridApi) => {
  return (
    api
      .getAllGridColumns()
      ?.filter(i => !i.getColDef().suppressColumnsToolPanel)
      .map(c => ({
        id: c.getColId(),
        colId: c.getColId(),
        title: c.getColDef().headerName || "",
        hide: !c.isVisible(),
        width: c.getActualWidth(),
        sortIndex: c.getSortIndex() || 0,
      })) || []
  );
};

const mergeStateConfigs = (viewConfig: TConfigDataObjects, allTableColumns: TConfigDataObjects): TConfigDataObjects => {
  return allTableColumns.map(col => {
    const existingColConfig = viewConfig.find(c => col.colId === c.colId);
    if (existingColConfig) {
      return existingColConfig;
    } else {
      return { ...col, hide: true };
    }
  });
};

export const applyActiveColumnStateConfig = (api?: GridApi) => {
  if (!api) {
    return;
  }

  if (appStore.env.activeTableViewConfigId === defaultTableViewConfigTabId) {
    api.resetColumnState();
    appStore.env.showAllTableColumns();
  } else {
    const activeTableViewConfig = appStore.workspaceModel?.tableViewConfigs.get(appStore.env.activeTableViewConfigId);
    activeTableViewConfig &&
      api.applyColumnState({
        state: mergeStateConfigs(activeTableViewConfig.configObject, parseGridColumnsToState(api)),
        applyOrder: true,
      });
  }
};

export const updateConfigWithAddedColumn = (api: GridApi, colId = "") => {
  api.moveColumns([colId], -1);
  api.setColumnsVisible([colId], true);
  appStore.env.tableViewGridApi?.setFocusedCell(0, colId);
  appStore.env.tableViewGridApi?.ensureColumnVisible(colId, "middle");
  const columnsState = parseGridColumnsToState(api);
  const actualConfig = getStringActualColumnsConfig(columnsState);
  const activeTableViewConfig = appStore.workspaceModel?.tableViewConfigs.get(appStore.env.activeTableViewConfigId);
  activeTableViewConfig?.setConfig(actualConfig);
  applyActiveColumnStateConfig(api);
};
