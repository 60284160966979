import { ColDef, GetRowIdParams, ICellRenderer, ValueGetterParams } from "ag-grid-community";

import ThumbnailCell from "@components/CatalogItems/Cells/PdmThumbnailCell";
import BlockStatusCell from "@components/Modeling/ModelingFrame/Table/TableComponent/Cells/BlockStatusCell";
import { BomColumnType, BomMetaColumn } from "@rollup-api/models/bom";
import { IBomColumn } from "@store/BomTable/BomColumnStore";
import { BomTableNode, IBomTable } from "@store/BomTable/BomTableStore";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { getSubtotalColumnValue } from "@utilities";

import ValueCell from "./Cells/ValueCell";
import { actionsColumnDef, bomTablePrefix, checkboxColumnDef, createNewColumnDef } from "./constants";

export const getCellRenderer = (columnType: BomColumnType, metaColumn: BomMetaColumn | null) => {
  if (columnType === BomColumnType.Status) {
    return BlockStatusCell;
  } else if (columnType === BomColumnType.Meta) {
    switch (metaColumn) {
      case BomMetaColumn.Thumbnail:
        return ThumbnailCell;
    }
  }

  // hack the circle dependency error
  return ValueCell as unknown as ICellRenderer;
};

const getCellClass = (columnType: BomColumnType, metaColumn: BomMetaColumn | null, isNumeric: boolean) => {
  let cellClass = isNumeric ? "ag-right-aligned-cell " : "";

  if (columnType === BomColumnType.Status) {
    cellClass += "ag-status-cell";
  } else if (columnType === BomColumnType.Meta) {
    if (metaColumn === BomMetaColumn.Thumbnail) {
      cellClass += "ag-image-cell";
    }
  } else {
    cellClass += "ag-value-cell";
  }

  return cellClass;
};

const getCellWidth = (column: IBomColumn) => {
  if (column.columnType === BomColumnType.Meta) {
    switch (column.metaColumn) {
      case BomMetaColumn.Thumbnail:
        return 69;
    }
  }

  return column.width || undefined;
};

const cellValueGetter = (node: ValueGetterParams<BomTableNode>, column: IBomColumn) => {
  if (node.data) {
    return column.getValue(node.data.catalogItem);
  }

  return "";
};

export const getRowId = (row: GetRowIdParams<BomTableNode>) => `${bomTablePrefix}-${row.data.catalogItem.id}`;

export const getColDefs = (columns: IBomColumn[]): ColDef<BomTableNode>[] => {
  const result: ColDef<BomTableNode>[] = [checkboxColumnDef, actionsColumnDef];
  const isLargeScreen = window.innerWidth > 1600;

  for (const column of columns) {
    const isThumbnailColumn = column.metaColumn === BomMetaColumn.Thumbnail;
    const suppressSizeToFit = isLargeScreen || !!column.width || isThumbnailColumn;

    result.push({
      suppressSizeToFit,
      flex: suppressSizeToFit ? undefined : 1,
      headerName: column.label,
      suppressColumnsToolPanel: true,
      colId: column.id,
      resizable: !isThumbnailColumn,
      width: getCellWidth(column),
      cellEditorPopup: column.columnType === BomColumnType.Status,
      minWidth: 130,
      cellClass: getCellClass(column.columnType, column.metaColumn, column.isNumeric),
      cellRenderer: getCellRenderer(column.columnType, column.metaColumn),
      valueGetter: node => cellValueGetter(node, column),
      suppressMovable: column.suppressMovable,
      lockPosition: column.lockPosition,
      rowGroup: column.rowGroup,
      cellEditorParams: {
        projectStatusDefinition: column.statusDefinition,
        getValue: column.getValue,
      },
      cellRendererParams: {
        bomColumn: column,
        propertyDefinition: column.propertyDefinition,
        statusDefinition: column.statusDefinition,
      },
      headerComponentParams: {
        bomColumn: column,
        hideNav: column.metaColumn === BomMetaColumn.Name,
      },
    });
  }
  result.push(createNewColumnDef);
  return result;
};

export const getTableTotal = (table: IBomTable): string => {
  const totalValue = table.validRows.reduce((currentValue: number, catalogItem: ICatalogItem) => {
    const value = getSubtotalColumnValue(catalogItem, true) as number;
    return value + currentValue;
  }, 0);

  // @todo figure out what currency to use if PDM item has different currencies
  // @todo include currency calculations?
  return `${totalValue} $`;
};
