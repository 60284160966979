import { useState } from "react";
import { observer } from "mobx-react";

import Button from "@components/Button/Button";
import { PopupMenu } from "@components/Shared/PopupMenu";
import appStore from "@store/AppStore";

import styles from "./RequirementsUiOptions.module.scss";

const RequirementsUiOptions = () => {
  const [isPopupMenuOpen, setIsPopupMenuOpen] = useState(false);

  const handleFitColumnsToGrid = () => {
    appStore.env.requirementsTableGridApi?.sizeColumnsToFit();
  };

  const handleFitColumnsToContent = () => {
    const { requirementsTableGridApi: api } = appStore.env;
    const columnIds =
      api
        ?.getColumns()
        ?.filter(col => !col.getColDef().suppressSizeToFit)
        .map(col => col.getColId()) ?? [];
    api?.autoSizeColumns(columnIds);
  };

  const renderMenu = () => {
    return (
      <div className={styles.requirementsUiOptionsMenu}>
        <Button
          className={styles.requirementsUiOptionsButton}
          onClick={handleFitColumnsToGrid}
          icon="horizontal-inbetween"
          e2eIdentifiers="resize-columns"
          minimal
        >
          Fit to grid
        </Button>
        <Button
          className={styles.requirementsUiOptionsButton}
          onClick={handleFitColumnsToContent}
          icon="arrows-horizontal"
          e2eIdentifiers="resize-columns"
          minimal
        >
          Fit to content
        </Button>
      </div>
    );
  };

  return (
    <PopupMenu
      buttonText="Group"
      popoverProps={{ isOpen: isPopupMenuOpen, onInteraction: setIsPopupMenuOpen }}
      content={renderMenu()}
      buttonProps={{ icon: "cog", text: "UI Options", minimal: true }}
      e2eIdentifiers="open-group-by-menu"
    />
  );
};

export default observer(RequirementsUiOptions);
