import { isVercel } from "@rollup-api/api/auth";
import { EPageName } from "@router/hooks/useAppNavigate";

import { rollupClient } from "../core/api";

export const getReportLink = (workspaceId: string, reportId: string) => {
  return getWorkspaceLink(workspaceId) + `/${EPageName.Knowledgebase}/${reportId}`;
};

export const getAnalysisLink = (workspaceId: string, analysisId: string) => {
  return getWorkspaceLink(workspaceId) + `/${EPageName.Analysis}/${analysisId}`;
};

export const getWorkspaceLink = (workspaceId: string) => {
  return window.location.origin + `/workspaces/${workspaceId}`;
};

export const getAssetLink = (src?: string, force?: boolean) => {
  // Asset links are only needed for vercel deployments
  if (!src || (!isVercel() && !force)) {
    return src;
  }

  // Determine if the image src is an api call
  try {
    const backendUrl = new URL(rollupClient.url);
    const srcUrl = new URL(src);

    const assetRegex = /(download|redirect|thumbnail)\/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}$/gm;
    if (
      rollupClient.auth.accessToken &&
      srcUrl.host === backendUrl.host &&
      srcUrl.pathname.startsWith(backendUrl.pathname) &&
      srcUrl.pathname.match(assetRegex)
    ) {
      srcUrl.searchParams.set("rollup_token", rollupClient.auth.accessToken);
      src = srcUrl.toString();
    }
    return src;
  } catch (error) {
    console.warn("URL is invalid");
    return src;
  }
};

export const isSettingsPage = () => {
  const { pathname } = window.location;
  const splitPathname = pathname.split("/");
  return splitPathname[1] === "settings";
};
