import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { ContextMenu, Icon, InputGroup } from "@blueprintjs/core";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { ENTER_KEY } from "@constants/keys";
import { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { copyToClipboard } from "@utilities";
import { Text, TextVariant } from "src/ui/Text";

import styles from "./StatusInstanceLinkCell.module.scss";

type StatusInstanceSelectCellProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

const StatusInstanceLinkCell = (props: StatusInstanceSelectCellProps) => {
  const { statusDefinition, statusInstance, onAddStatusInstance } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [disableContext, setDisableContext] = useState(false);
  const urlValue = statusInstance?.urlValue;
  const [label, setLabel] = useState(urlValue?.label || "");
  const [url, setUrl] = useState(urlValue?.url || "");

  useEffect(() => {
    setLabel(urlValue?.label || "");
    setUrl(urlValue?.url || "");
  }, [urlValue]);

  const handleSubmit = async () => {
    if (label !== urlValue?.label || url !== urlValue?.url) {
      const instance = statusInstance ?? (await onAddStatusInstance(statusDefinition, url));
      return instance?.setValueFromURL({ url: url, label });
    }

    if (statusInstance && !url && !label) {
      statusInstance.parentBlock?.deleteStatusInstance(statusInstance);
    }
  };

  const handleConfirmOnEnter = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER_KEY) {
      await handleSubmit();
      setShowDropdown(false);
      setDisableContext(false);
    }
  };

  const onPopoverInteraction = async (nextOpenState: boolean) => {
    setShowDropdown(nextOpenState);

    if (!nextOpenState) {
      await handleSubmit();
      setDisableContext(false);
    }
  };

  const handleInputFocus = (event: ChangeEvent<HTMLInputElement>) => event.target.select();

  const renderEditor = () => {
    return (
      <div className={styles.statusInstanceLinkCellPopoverContent}>
        <InputGroup
          onFocus={handleInputFocus}
          autoFocus
          data-testid="label-input"
          placeholder="Label"
          onKeyDown={handleConfirmOnEnter}
          value={label}
          onChange={e => setLabel(e.target.value)}
        />
        <InputGroup
          onFocus={handleInputFocus}
          data-testid="link-input"
          placeholder="Link"
          onKeyDown={handleConfirmOnEnter}
          leftIcon="link"
          value={url}
          onChange={e => setUrl(e.target.value)}
        />
      </div>
    );
  };

  const renderLink = () => {
    if (url) {
      return (
        <a href={url} target="_blank" className={styles.statusInstanceLinkCellLink} rel="noreferrer">
          {label || url}
        </a>
      );
    }

    return <Text variant={TextVariant.Caption}>Add link</Text>;
  };

  const handleStartLinkEdit = () => {
    setShowDropdown(true);
    setDisableContext(true);
  };

  const handleCopyToClipboard = () => {
    setDisableContext(true);
    copyToClipboard(url).then(() => setDisableContext(false));
  };

  const renderContextMenu = () => {
    return (
      <div className={classNames(styles.statusInstanceLinkCellPopoverContent, styles.statusInstanceLinkCellContextContent)}>
        <Icon icon="link" />
        {renderLink()}
        <Button e2eIdentifiers="edit-link-button" minimal icon="edit" onClick={handleStartLinkEdit} />
        <Button e2eIdentifiers="copy-link-button" minimal icon="duplicate" onClick={handleCopyToClipboard} />
      </div>
    );
  };

  return (
    <ContextMenu disabled={disableContext || !url} content={renderContextMenu()}>
      <div className={styles.statusInstanceLinkCell}>
        <Popover
          onInteraction={onPopoverInteraction}
          isOpen={showDropdown}
          minimal
          interactionKind="hover"
          content={renderEditor()}
          placement="right"
        >
          {renderLink()}
        </Popover>
      </div>
    </ContextMenu>
  );
};

export default observer(StatusInstanceLinkCell);
