import { useState } from "react";
import { Intent } from "@blueprintjs/core";
import { useHandleDeleteDocument } from "@hooks/useHandleDeleteDocument";
import { useWorkspace } from "@hooks/useWorkspace";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import RequirementsActionMenu from "@components/Requirements/ActionMenu/RequirementsActionMenu";
import { RequirementsUiOptions } from "@components/Requirements/Page/RequirementsUiOptions";
import { RequirementsViewMode } from "@components/Requirements/Requirements.types";
import RequirementsSidebarContextMenu from "@components/Requirements/RequirementsSidebar/RequirementsSidebarContextMenu";
import RequirementsTable from "@components/Requirements/RequirementsTable/RequirementsTable";
import useReqTableExport from "@components/Requirements/RequirementsTable/useReqTableExport";
import { ModulePageHeader } from "@components/Shared/ModulePageHeader";
import { EPageName } from "@router/hooks/useAppNavigate";
import appStore from "@store/AppStore";
import { IRequirementsPage } from "@store/Requirements/RequirementsPageStore";

import { RequirementsCsvImportDialog } from "../RequirementsCsvImportDialog";
import { RequirementsDocumentView } from "../RequirementsDocumentView";

import styles from "./RequirementsPage.module.scss";

type RequirementsPageProps = {
  reqPage: IRequirementsPage;
};

function RequirementsPage({ reqPage }: RequirementsPageProps) {
  const workspace = useWorkspace();
  const { handleExport, exporting } = useReqTableExport(reqPage);
  const { requirementPageViewMode: viewMode, setRequirementPageViewMode: setViewMode } = appStore.env;
  const [showCsvImportDialog, setShowCsvImportDialog] = useState(false);

  const handleDelete = useHandleDeleteDocument({
    document: reqPage,
    docArray: workspace.requirementsModule.values,
    pageName: EPageName.Requirements,
    deleteDocument: appStore.workspaceModel?.deleteRequirementsPage,
  });

  const renderButtons = () => {
    return (
      <div className={styles.requirementPageButtonsContainer}>
        <Button onClick={() => setShowCsvImportDialog(true)} intent={Intent.PRIMARY} icon="import" e2eIdentifiers="import-csv">
          Import CSV
        </Button>
        {viewMode === RequirementsViewMode.Table && <RequirementsUiOptions />}
      </div>
    );
  };

  const renderViewModes = () => {
    return (
      <>
        <Button loading={exporting} icon="export" e2eIdentifiers="export-requirements" onClick={handleExport} tooltip="Export" minimal />
        <div className={styles.requirementPageViewModeButtons}>
          <Button
            icon="th-list"
            e2eIdentifiers="table-view"
            onClick={() => setViewMode(RequirementsViewMode.Table)}
            active={viewMode === RequirementsViewMode.Table}
            tooltip="Table view"
            minimal
          />
          <Button
            icon="document"
            e2eIdentifiers="document-view"
            onClick={() => setViewMode(RequirementsViewMode.Document)}
            active={viewMode === RequirementsViewMode.Document}
            tooltip="Document view"
            minimal
          />
        </div>
      </>
    );
  };

  const renderView = () => {
    switch (viewMode) {
      case RequirementsViewMode.Document:
        return <RequirementsDocumentView reqPage={reqPage} />;
      default:
        return <RequirementsTable reqPage={reqPage} rowData={reqPage.validatedBlocks} />;
    }
  };

  return (
    <div className={styles.requirementPage} data-dragselectable={viewMode === RequirementsViewMode.Document}>
      {showCsvImportDialog && <RequirementsCsvImportDialog reqPage={reqPage} onClose={() => setShowCsvImportDialog(false)} />}
      <ModulePageHeader<IRequirementsPage>
        entity={reqPage}
        showLink
        entityName="requirements page"
        contextMenu={<RequirementsSidebarContextMenu reqPage={reqPage} />}
        moreActions={<RequirementsActionMenu reqPage={reqPage} onDelete={handleDelete} />}
        elementByTheTitle={renderButtons()}
        rightElement={renderViewModes()}
      />
      {renderView()}
    </div>
  );
}

export default observer(RequirementsPage);
