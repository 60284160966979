import { Intent } from "@blueprintjs/core";
import { EntityMapLike, toArray } from "@rollup-io/engineering";
import isNull from "lodash/isNull";
import omitBy from "lodash/omitBy";
import { detach, flow, IAnyModelType, types } from "mobx-state-tree";

import { showApiErrorToast, showToast } from "@components/UiLayers/toaster";
import { Import, ImportExtensionType, ImportType } from "@rollup-api/models";
import { CatalogItem, CatalogItemRemoved } from "@rollup-api/models/catalogItem/catalogItem.model";
import {
  CatalogItemChildRef,
  CreateCatalogItemDto,
  CreateCatalogItemReferenceDto,
  CreateCatalogItemResponse,
  CreateCatalogItemVersionDto,
  UpdateCatalogItemDto,
  UpdateCatalogItemVersionDto,
} from "@rollup-api/models/catalogItem/catalogItemDtos";
import { CatalogItemVersion } from "@rollup-api/models/catalogItem/catalogItemVersion.model";
import { RevisionCodeType } from "@rollup-api/models/organizationSettings/organizationSettings.model";
import appStore from "@store/AppStore";
import { ICatalogItemReference } from "@store/CatalogItem/CatalogItemReferenceStore";
import { CatalogItemReferenceStore } from "@store/CatalogItem/CatalogItemReferenceStore";
import { CatalogItemStore, ICatalogItem } from "@store/CatalogItem/CatalogItemStore";
import { CatalogItemVersionStore, ICatalogItemVersion } from "@store/CatalogItem/CatalogItemVersionStore";
import { FeatureFlag } from "@store/FeatureFlagsStore";
import { convertTimestamp } from "@utilities/Date";
import { rollupClient } from "src/core/api";

export const CatalogItemModuleStore = types
  .model("CatalogItemModuleStore", {
    catalogItemsMap: types.map(types.late((): IAnyModelType => CatalogItemStore)),
    catalogItemVersionsMap: types.map(types.late((): IAnyModelType => CatalogItemVersionStore)),
    catalogItemReferencesMap: types.map(types.late((): IAnyModelType => CatalogItemReferenceStore)),
  })
  .views(self => ({
    get catalogItemRevisionCodeType(): RevisionCodeType {
      return appStore.orgModel.settings?.catalogItemRevisionCodeType || RevisionCodeType.Incremental;
    },
    get catalogItemWorkspaceLevel(): boolean {
      return appStore.orgModel.settings?.catalogItemWorkspaceLevel === undefined
        ? false
        : appStore.env.featureFlags.enabled(FeatureFlag.CATALOG_ITEMS_WORKSPACE_LEVEL) &&
            appStore.orgModel.settings.catalogItemWorkspaceLevel;
    },
    get catalogItems(): ICatalogItem[] {
      const activeWorkspaceId = appStore.workspaceModel?.id;
      const isWorkspaceLevel = this.catalogItemWorkspaceLevel;
      const items = toArray<ICatalogItem>(self.catalogItemsMap as EntityMapLike<ICatalogItem>);

      if (isWorkspaceLevel && activeWorkspaceId) {
        return items.filter(i => !i.workspaceId || i.workspaceId === activeWorkspaceId);
      }

      return items;
    },
    get workspaceCatalogItems(): ICatalogItem[] {
      const items = toArray<ICatalogItem>(self.catalogItemsMap as EntityMapLike<ICatalogItem>);
      const activeWorkspaceId = appStore.workspaceModel?.id;

      return items.filter(i => (activeWorkspaceId ? !i.workspaceId || i.workspaceId === activeWorkspaceId : !i.workspaceId));
    },
    get catalogItemVersions(): ICatalogItemVersion[] {
      return toArray<ICatalogItemVersion>(self.catalogItemVersionsMap as EntityMapLike<ICatalogItemVersion>);
    },
    get catalogItemReferences(): ICatalogItemReference[] {
      return toArray<ICatalogItemReference>(self.catalogItemReferencesMap as EntityMapLike<ICatalogItemReference>);
    },
    getCatalogItem(id: string): ICatalogItem | undefined {
      return self.catalogItemsMap.get(id);
    },
    getCatalogItemReference(id: string): ICatalogItemReference | undefined {
      return self.catalogItemReferencesMap.get(id);
    },
    getCatalogItemVersion(id: string): ICatalogItemVersion | undefined {
      return self.catalogItemVersionsMap.get(id);
    },
  }))
  .views(self => ({
    getCatalogItemVersionByNodeId(nodeId: string): ICatalogItemVersion | undefined {
      return self.catalogItemVersions.find(v => v.metaData?.nodeId === nodeId);
    },
    get totalItems(): number {
      const itemsLength = self.catalogItems.length;
      const refsLength = self.catalogItems.reduce((acc, item) => acc + item.references.length, 0);

      return appStore.ui.showPdmFlatten ? itemsLength : itemsLength + refsLength;
    },
    get refIds(): string[] {
      return self.catalogItemReferences.map(r => r.id);
    },
    get parentlessItems(): ICatalogItem[] {
      return self.catalogItems.filter(i => !i.parentItem);
    },
    get parentlessOrgWideItems(): ICatalogItem[] {
      return this.parentlessItems.filter(i => !i.workspaceId);
    },
    getParentlessWorkspaceItems(workspaceId: string): ICatalogItem[] {
      return this.parentlessItems.filter(i => i.workspaceId === workspaceId);
    },
    getAttachmentParts(attachmentId: string): ICatalogItemVersion[] {
      return self.catalogItemVersions.filter(v => v.attachmentId === attachmentId);
    },
    getChildRefs(attachmentId: string, nodeId: string): CatalogItemChildRef[] {
      const versions = self.catalogItemVersions.filter(v => v.attachmentId === attachmentId);
      const catalogItems: ICatalogItem[] = versions.map(v => v.catalogItem);

      return catalogItems.reduce((acc: CatalogItemChildRef[], item) => {
        const childRefs = item.references.filter(ref => ref.refId === nodeId);
        return [...acc, ...childRefs];
      }, []);
    },
  }))
  .actions(self => ({
    removeCatalogItemReferenceByCatalogItemId(catalogItemId: string) {
      const references = self.catalogItemReferences.filter(
        r => r.referencedCatalogItem?.id === catalogItemId || r.parentCatalogItem?.id === catalogItemId
      );

      if (references.length) {
        for (const reference of references) {
          if (reference.parentCatalogItem) {
            const parentItem = self.catalogItemsMap.get(reference.parentCatalogItem.id);
            if (parentItem) {
              parentItem.childReferences = parentItem.childReferences.filter((r: ICatalogItemReference) => r.id !== reference.id);
            }
          }

          if (reference.referencedCatalogItem) {
            const referencedItem = self.catalogItemsMap.get(reference.referencedCatalogItem.id);
            if (referencedItem) {
              referencedItem.references = referencedItem.references.filter((r: ICatalogItemReference) => r.id !== reference.id);
            }
          }

          self.catalogItemReferencesMap.delete(reference.id);
        }
      }
    },
    addExistingCatalogItemWithVersion(data: { catalogItem: CatalogItem; catalogItemVersion: CatalogItemVersion }) {
      const { catalogItem, catalogItemVersion } = data;
      const cleanedVersion = omitBy<CatalogItemVersion>(catalogItemVersion, isNull);

      self.catalogItemVersionsMap.set(catalogItemVersion.id, {
        ...cleanedVersion,
        createdAt: convertTimestamp(catalogItemVersion.createdAt),
        updatedAt: convertTimestamp(catalogItemVersion.updatedAt),
        catalogItem: catalogItem.id,
        revisions: [],
      });
      self.catalogItemsMap.set(catalogItem.id, {
        ...catalogItem,
        ...(catalogItem.parentItemId && {
          parentItem: catalogItem.parentItemId,
        }),
        createdAt: convertTimestamp(catalogItem.createdAt),
        updatedAt: convertTimestamp(catalogItem.updatedAt),
        versions: [catalogItemVersion.id],
      });

      if (catalogItem.parentItemId) {
        const parentItem = self.catalogItemsMap.get(catalogItem.parentItemId);
        if (parentItem) {
          parentItem.children.push(catalogItem.id);
        }
      }

      if (appStore.env.catalogItemTableGridApi) {
        const item = self.catalogItemsMap.get(catalogItem.id);
        appStore.env.catalogItemTableGridApi?.applyTransaction({
          add: [{ catalogItem: item, path: item.pathIds }],
        });
      }
    },
    removeExistingCatalogItem(catalogItemRemoved: CatalogItemRemoved) {
      const { id, versions } = catalogItemRemoved;

      this.removeCatalogItemReferenceByCatalogItemId(id);

      if (appStore.ui.catalogItemPreviewId === id) {
        appStore.ui.setCatalogItemPreviewId();
      }
      if (appStore.env.catalogItemTableGridApi) {
        appStore.env.catalogItemTableGridApi?.applyTransaction({ remove: [{ catalogItem: { id } as ICatalogItem, path: [] }] });
      }
      versions?.forEach(v => self.catalogItemVersionsMap.delete(v));
      detach(self.catalogItemsMap.get(id));
    },
    addCatalogItemReference: flow(function* removeCatalogItemReference(data: CreateCatalogItemReferenceDto, notify = true) {
      self.catalogItemReferencesMap.set(data.id, {
        ...data,
        referencedCatalogItem: data.referencedCatalogItemId,
        parentCatalogItem: data.parentCatalogItemId,
      });

      self.catalogItemsMap.get(data.referencedCatalogItemId)?.references.push(data.id);
      self.catalogItemsMap.get(data.parentCatalogItemId)?.childReferences.push(data.id);

      if (notify) {
        try {
          yield rollupClient.catalogItemReferences.create(data);
          showToast("Catalog item reference created", Intent.SUCCESS);
        } catch (error) {
          console.error(error);
          showApiErrorToast("Error creating catalog item reference", error as Error);
        }
      }
    }),
    removeCatalogItemReference: flow(function* removeCatalogItemReference(id: string, notify = true) {
      self.catalogItemReferencesMap.delete(id);

      if (notify) {
        try {
          yield rollupClient.catalogItemReferences.remove(id);
          showToast("Catalog item reference deleted", Intent.SUCCESS);
        } catch (error) {
          console.error(error);
          showApiErrorToast("Error deleting catalog item reference", error as Error);
        }
      }
    }),
  }))
  .actions(self => ({
    bulkRemoveCatalogItemReference: flow(function* bulkRemoveCatalogItemReference(ids: string[], notify = true) {
      ids.forEach(id => self.removeCatalogItemReference(id, false));

      if (notify) {
        try {
          yield rollupClient.catalogItemReferences.bulkRemove(ids);
          showToast("Catalog item reference deleted", Intent.SUCCESS);
        } catch (error) {
          console.error(error);
          showApiErrorToast("Error deleting catalog item references", error as Error);
        }
      }
    }),
    updateCatalogItem: flow(function* updateCatalogItem(id: string, updateDto: UpdateCatalogItemDto) {
      try {
        const result: CatalogItem = yield rollupClient.catalogItems.update(id, updateDto);
        const catalogItem: ICatalogItem = self.catalogItemsMap.get(result.id);
        catalogItem.patch(updateDto);
        showToast("Catalog item updated", "success");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error updating catalog item");
      }
    }),
    updateCatalogItemVersion: flow(function* updateCatalogItemVersion(versionId: string, updateDto: UpdateCatalogItemVersionDto) {
      try {
        const result: CatalogItemVersion = yield rollupClient.catalogItemVersions.update(versionId, updateDto);
        self.catalogItemVersionsMap.set(result.id, {
          ...result,
          catalogItem: result.catalogItemId,
          createdAt: convertTimestamp(new Date(result.createdAt).toISOString()),
          updatedAt: convertTimestamp(new Date(result.updatedAt).toISOString()),
        });
        showToast("Catalog item version updated", "success");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error updating catalog item version");
      }
    }),
    createCatalogItemVersion: flow(function* createCatalogItemVersion(createDto: CreateCatalogItemVersionDto) {
      try {
        const result: CatalogItemVersion = yield rollupClient.catalogItemVersions.create(createDto);
        self.catalogItemVersionsMap.set(result.id, {
          ...result,
          catalogItem: result.catalogItemId,
          createdAt: convertTimestamp(new Date(result.createdAt).toISOString()),
          updatedAt: convertTimestamp(new Date(result.updatedAt).toISOString()),
        });
        self.catalogItemsMap.get(result.catalogItemId)?.versions.push(result.id);
        showToast("Catalog item version created", "success");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error creating catalog item version");
      }
    }),
    createCsvCatalogItem: flow(function* createCsvCatalogItem(attachmentId: string, file: File, columnMap: string, workspaceId?: string) {
      try {
        const result: Import = yield rollupClient.imports.create({
          type: ImportType.CatalogItem,
          attachmentId,
          ...(workspaceId && { workspaceId }),
          originalFileName: file.name,
          columnMap,
        });
        if (!result) {
          showApiErrorToast("Error creating csv based catalog items", new Error());
          return;
        }
        showToast("Successfully started catalog items import process", "success", "info-sign");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error creating csv based catalog items", error as Error);
      }
    }),
    createCatalogItemFromFile: flow(function* createCadCatalogItem(attachmentId: string, file: File, workspaceId?: string) {
      try {
        const result: Import = yield rollupClient.imports.create(
          {
            type: ImportType.CatalogItem,
            attachmentId,
            ...(workspaceId && { workspaceId }),
            originalFileName: file.name,
          },
          ImportExtensionType.CAD
        );
        if (!result) {
          showApiErrorToast("Error creating File based catalog items", new Error());
          return;
        }
        appStore.orgModel.addExistingImport(result);
        showToast("Successfully started catalog items import process", "success", "info-sign");
      } catch (error) {
        console.error(error);
        showApiErrorToast("Error creating File based catalog items", error as Error);
      }
    }),
    createCatalogItemsFromBlocks: flow(function* createCatalogItemsFromBlocks(blockIds: string[], workspaceId?: string) {
      try {
        const result: CreateCatalogItemResponse[] = yield rollupClient.catalogItems.createFromBlocks(blockIds, workspaceId);
        result.forEach(r => self.addExistingCatalogItemWithVersion(r));
      } catch (error) {
        showApiErrorToast("Error creating catalog items from blocks", error as Error);
        console.error(error);
      }
    }),
    createCatalogItem: flow(function* createCatalogItem(createItemDto: CreateCatalogItemDto) {
      try {
        const { partNumberSchemaId } = createItemDto;
        const result: CreateCatalogItemResponse = yield rollupClient.catalogItems.create(createItemDto);
        if (partNumberSchemaId) {
          const schema = appStore.orgModel.partNumberSchemas.get(partNumberSchemaId);
          if (schema) {
            schema.patch({ currentCount: (schema.currentCount || 0) + 1 });
          }
        }
        self.addExistingCatalogItemWithVersion(result);
        return result.catalogItem.id;
      } catch (error) {
        console.error(error);
        return;
      }
    }),
    fetchCatalogItems: flow(function* fetchCatalogItems() {
      const catalogItemsResponse: CatalogItem[] = yield rollupClient.catalogItems.retrieveList();

      catalogItemsResponse.forEach((i: CatalogItem) => {
        self.catalogItemsMap.set(i.id, {
          ...i,
          createdAt: convertTimestamp(new Date(i.createdAt).toISOString()),
          updatedAt: convertTimestamp(new Date(i.updatedAt).toISOString()),
        });
      });
    }),
    fetchCatalogItemVersions: flow(function* fetchCatalogItemVersions() {
      const catalogItemVersionsResponse: CatalogItemVersion[] = yield rollupClient.catalogItemVersions.retrieveList();

      catalogItemVersionsResponse.forEach((i: CatalogItemVersion) => {
        self.catalogItemVersionsMap.set(i.id, {
          ...i,
          createdAt: convertTimestamp(new Date(i.createdAt).toISOString()),
          updatedAt: convertTimestamp(new Date(i.updatedAt).toISOString()),
        });
      });
    }),
    removeCatalogItem: flow(function* removeCatalogItem(id: string) {
      try {
        const res: CatalogItemRemoved = yield rollupClient.catalogItems.remove(id);
        if (res) {
          self.removeExistingCatalogItem(res);
          showToast("Catalog item deleted", "success", "info-sign");
        } else {
          showApiErrorToast("Error deleting catalog item", new Error());
        }
      } catch (error) {
        showApiErrorToast("Error deleting catalog item", error as Error);
        console.error(error);
      }
    }),
    removeCatalogItems: flow(function* removeCatalogItems(ids: string[]) {
      try {
        const res: CatalogItemRemoved[] = yield rollupClient.catalogItems.bulkRemove(ids);
        if (res?.length) {
          res.forEach(item => self.removeExistingCatalogItem(item));
          showToast("Catalog items deleted", "success", "info-sign");
        } else {
          showApiErrorToast("Error deleting catalog items", new Error());
        }
      } catch (error) {
        showApiErrorToast("Error deleting catalog items", error as Error);
        console.error(error);
      }
    }),
  }));
