import { BomMetaColumn } from "@rollup-api/models";
import { ICatalogItem } from "@store/CatalogItem/CatalogItemStore";

export function getSubtotalColumnValue(catalogItem: ICatalogItem, returnNumber?: boolean): string | number {
  const cost = catalogItem.cost;
  const currency = catalogItem.costCurrency;

  if (returnNumber) {
    return cost;
  }

  return `${cost} ${currency}`;
}

export function getBomColumnLabelFromMetaColumn(metaColumn: BomMetaColumn): string {
  switch (metaColumn) {
    case BomMetaColumn.Id:
      return "ID";
    case BomMetaColumn.Name:
      return "Name";
    case BomMetaColumn.PartNumber:
      return "Part number";
    case BomMetaColumn.ProductGroup:
      return "Product";
    case BomMetaColumn.Level:
      return "Level";
    case BomMetaColumn.BlockType:
      return "Block type";
    case BomMetaColumn.Notes:
      return "Notes";
    case BomMetaColumn.File:
      return "File";
    case BomMetaColumn.Thumbnail:
      return "Thumbnail";
    case BomMetaColumn.UnitOfMeasure:
      return "Unit of measure";
    case BomMetaColumn.Subtotal:
      return "Subtotal";
    default:
      return "Unknown";
  }
}

export const getBomMetaColumns = (): BomMetaColumn[] => [
  BomMetaColumn.Name,
  BomMetaColumn.PartNumber,
  BomMetaColumn.Thumbnail,
  BomMetaColumn.File,
  BomMetaColumn.ProductGroup,
  BomMetaColumn.Level,
  BomMetaColumn.Notes,
  BomMetaColumn.Subtotal,
];
