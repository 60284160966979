import assignIn from "lodash/assignIn";
import { IAnyModelType, Instance, IType, SnapshotIn, SnapshotOut, types } from "mobx-state-tree";
import { Socket } from "socket.io-client";

import { CreateStatusDefinitionDto, StatusDefinitionUpdateDto } from "@rollup-api/models";
import { StatusOptionStore } from "@store/StatusOptionStore";
import { parentWorkspace } from "@utilities";

import { rollupClient } from "../core/api";

import appStore from "./AppStore";

export enum StatusType {
  text = "text",
  url = "url",
  number = "number",
  check = "check",
  singleSelect = "single-select",
  multiSelect = "multi-select",
  mention = "mention",
  date = "date",
}

export const StatusDefinitionStore = types
  .model("StatusDefinition", {
    id: types.identifier,
    label: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    type: types.enumeration("StatusType", [...Object.values(StatusType)]),
    statusOptions: types.array(types.safeReference(types.late((): IAnyModelType => StatusOptionStore))),
    orderIndex: types.optional(types.number, 0),
    bomTableId: types.maybeNull(types.string),
  })
  .actions(self => ({
    patch(update: StatusDefinitionUpdateDto) {
      // Prevent updating of fixed properties.
      // orderIndex should only be changed via reordering
      const invalidFields = ["id", "orderIndex"];
      const updateKeys = Object.keys(update);
      for (const field of invalidFields) {
        if (updateKeys.includes(field)) {
          return false;
        }
      }

      try {
        assignIn(self, update);
        return true;
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    setLabel(label: string) {
      if (label == "") {
        label = "Untitled Status";
      }
      // Prevent duplicate definitions
      const lowerCaseLabel = label?.toLowerCase();
      if (
        parentWorkspace(self)?.statusDefinitions?.find(p => p.label?.toLowerCase() === lowerCaseLabel && p.bomTableId === self.bomTableId)
      ) {
        return;
      }
      self.label = label;
      rollupClient.statusDefinitions.update(self.id, { label });
    },
    setDescription(description: string) {
      self.description = description;
      rollupClient.statusDefinitions.update(self.id, { description });
    },
    setType(type: StatusType) {
      parentWorkspace(self)?.migrateStatusType(self as IStatusDefinition, type, true);
    },
    addStatusOptionReference(option: { id: string }) {
      if (!option?.id || self.statusOptions?.find(o => o.id === option.id)) {
        return false;
      }
      self.statusOptions.push(option.id);
      // TODO: how do we replicate this
    },
  }));

export function subscribeToStatusDefinitionEvents(socket: Socket) {
  socket.on("createStatusDefinition", (data: { workspaceId: string; createStatusDefinitionDto: CreateStatusDefinitionDto }) => {
    if (data.createStatusDefinitionDto?.id && data.workspaceId === appStore.workspaceModel?.id) {
      const { label, type, description, id } = data.createStatusDefinitionDto;
      appStore.workspaceModel.addNewStatusDefinition(label, type as StatusType, description, id, false);
    }
  });

  socket.on("deleteStatusDefinition", (data: { workspaceId: string; id: string }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const statusDefinition = appStore.workspaceModel.statusDefinitionMap.get(data.id);
      if (statusDefinition) {
        appStore.workspaceModel.deleteStatusDefinition(statusDefinition, false);
      }
    }
  });

  socket.on("updateStatusDefinition", (data: { workspaceId: string; id: string; updateStatusDefinitionDto: StatusDefinitionUpdateDto }) => {
    if (data.id && data.workspaceId === appStore.workspaceModel?.id) {
      const statusDefinition = appStore.workspaceModel.statusDefinitionMap.get(data.id);
      statusDefinition?.patch(data.updateStatusDefinitionDto);
    }
  });

  socket.on(
    "reorderStatusDefinition",
    (data: { workspaceId: string; id: string; reorderStatusDefinitionDto: { destinationId: string } }) => {
      if (data.id && data.reorderStatusDefinitionDto?.destinationId && data.workspaceId === appStore.workspaceModel?.id) {
        const definition = appStore.workspaceModel.statusDefinitionMap.get(data.id);
        if (definition) {
          appStore.workspaceModel.moveStatusDefinition(data.id, data.reorderStatusDefinitionDto.destinationId, false);
        }
      }
    }
  );
}

export interface IStatusDefinition extends Instance<typeof StatusDefinitionStore> {}
export interface IStatusDefinitionSnapshotIn extends SnapshotIn<typeof StatusDefinitionStore> {}
interface IStatusDefinitionSnapshotOut extends SnapshotOut<typeof StatusDefinitionStore> {}
export interface IStatusDefinitionMobxType extends IType<IStatusDefinitionSnapshotIn, IStatusDefinitionSnapshotOut, IStatusDefinition> {}
