import { Outlet } from "react-router-dom";
import { useBool } from "@hooks/useBool/useBool";
import { observer } from "mobx-react";

import { FeatureLayout } from "@components/FeatureLayout";
import MenuPane from "@router/components/Panes/MenuPane";
import { useMenuPane } from "@router/components/Panes/useMenuPane";
import appStore from "@store/AppStore";
import { EPane } from "@store/PaneWidthStore";
import AnalysisSidebar from "src/Modules/Analysis/AnalysisSidebar/AnalysisSidebar";
import CreateAnalysisButton from "src/Modules/Common/CreateAnalysisButton";

import "./AnalysisLayout.scss";

function AnalysisLayout() {
  const { isSubNavPaneOpen, toggleSubNavPane } = appStore.env;
  const { open, toggle, minSize, maxSize } = useMenuPane({ isOpen: isSubNavPaneOpen, toggleIsOpen: toggleSubNavPane });
  const [menuHovered, { set: hoverMenu, unset: unhoverMenu }] = useBool();

  const renderCreateButton = (text = "Create analysis") => <CreateAnalysisButton text={text} />;

  const renderCollapsedMenuContent = (text?: string) => (
    <div className="analysis-layout--collapsed-content">
      {renderCreateButton(text)}
      <AnalysisSidebar minimal />
    </div>
  );

  return (
    <FeatureLayout
      paneWidth={appStore.env.paneWidth.subNav}
      menuHovered={menuHovered}
      dynamicPanelContent={<AnalysisSidebar />}
      minSize={minSize}
      maxSize={maxSize}
      topElement={renderCreateButton(menuHovered ? undefined : "")}
      onPaneWidthChange={(newSize: number) => appStore.env.paneWidth.updatePaneWidth(EPane.SUB_NAV, newSize)}
      onHoverMenu={hoverMenu}
      onUnhoverMenu={unhoverMenu}
      isSidePanelOpen={open}
    >
      <MenuPane
        open={open}
        toggle={toggle}
        title="Analysis"
        topElement={renderCreateButton()}
        collapsedContent={renderCollapsedMenuContent("")}
        onMouseEnter={hoverMenu}
        onMouseLeave={unhoverMenu}
      >
        <AnalysisSidebar />
      </MenuPane>

      <Outlet />
    </FeatureLayout>
  );
}

export default observer(AnalysisLayout);
