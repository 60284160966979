import { useEffect, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import CatalogItemLeadTimePicker from "@components/CatalogItemLeadTimePicker";
import CollapsibleCard from "@components/CatalogItemPreview/Components/CollapsibleCard";
import { periodToText } from "@components/CreateCatalogItemDialog/utils";
import PositiveNumberInput from "@components/PositiveNumberInput";
import appStore from "@store/AppStore";
import { PdmCard } from "@store/PdmCardsVisibilityStore";

import { tooltipContent } from "./constants";

import styles from "./SourcingCard.module.scss";

type Props = {
  activeVersion: string;
  isVertical?: boolean;
  disabled?: boolean;
};

const SourcingCard = (props: Props) => {
  const { activeVersion, isVertical, disabled } = props;
  const version = appStore.orgModel.catalogItems.getCatalogItemVersion(activeVersion);
  const [editMode, setEditMode] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [leadTime, setLeadTime] = useState("");

  useEffect(() => {
    setEditMode(false);
  }, [activeVersion]);

  useEffect(() => {
    setQuantity(version?.quantity || 0);
    setLeadTime(version?.leadTime || "");
    setSupplier(version?.supplier || "");
  }, [version]);

  const toggleEditMode = () => setEditMode(!editMode);

  const handleCancel = () => {
    setSupplier(version?.supplier || "");
    setQuantity(version?.quantity || 0);
    setLeadTime(version?.leadTime || "");
    toggleEditMode();
  };

  const handleSave = () => {
    appStore.orgModel.catalogItems.updateCatalogItemVersion(activeVersion, {
      leadTime,
      supplier,
      quantity,
    });
    toggleEditMode();
  };

  const getLeadTimeString = () => {
    if (leadTime) {
      return periodToText(leadTime);
    } else if (version?.leadTime) {
      return periodToText(version.leadTime);
    } else {
      return "";
    }
  };

  const renderRightActions = () => {
    if (disabled) {
      return;
    }

    if (editMode) {
      return (
        <>
          <Button onClick={handleCancel} minimal e2eIdentifiers="cancel-sourcing">
            Cancel
          </Button>
          <Button onClick={handleSave} intent="primary" e2eIdentifiers="save-sourcing">
            Save
          </Button>
        </>
      );
    }

    return (
      <Button onClick={toggleEditMode} className={styles.sourcingCardEdit} minimal e2eIdentifiers="edit-sourcing" icon="edit">
        Edit
      </Button>
    );
  };

  return (
    <CollapsibleCard
      className={classNames(styles.sourcingCard, { [styles.sourcingCardEditMode]: editMode })}
      visible={isVertical ? true : appStore.env.pdmCardVisibility.sourcing}
      onToggle={() => appStore.env.pdmCardVisibility.toggleCard(PdmCard.Sourcing)}
      width={isVertical ? "auto" : 260}
      disabled={isVertical}
      rightActions={renderRightActions()}
      title="Sourcing"
      tooltip={tooltipContent}
      version={editMode ? undefined : version?.index}
    >
      <div className={styles.sourcingCardRow}>
        <Text className={styles.sourcingCardLabel} variant={TextVariant.Caption}>
          Supplier:{" "}
        </Text>
        {editMode ? (
          <InputGroup fill value={supplier} onChange={e => setSupplier(e.target.value)} />
        ) : (
          <Text variant={TextVariant.Body}>{supplier}</Text>
        )}
      </div>
      <div className={styles.sourcingCardRow}>
        <Text className={styles.sourcingCardLabel} variant={TextVariant.Caption}>
          Lead Time:{" "}
        </Text>
        {editMode ? (
          <CatalogItemLeadTimePicker hideLabel defaultValue={version?.leadTime} onLeadTimeChange={setLeadTime} />
        ) : (
          <Text key={leadTime} variant={TextVariant.Body}>
            {getLeadTimeString()}
          </Text>
        )}
      </div>
      <div className={styles.sourcingCardRow}>
        <Text className={styles.sourcingCardLabel} variant={TextVariant.Caption}>
          Inventory:{" "}
        </Text>
        {editMode ? (
          <PositiveNumberInput className={styles.sourcingCardInventory} value={quantity} onChange={setQuantity} />
        ) : (
          <Text variant={TextVariant.Body}>{quantity}</Text>
        )}
      </div>
    </CollapsibleCard>
  );
};

export default observer(SourcingCard);
