import { useRef } from "react";
import GC from "@grapecity/spread-sheets";
import { Column, SpreadSheets, Worksheet } from "@grapecity/spread-sheets-react";

import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";

export interface SpreadsheetProps {
  className?: string;
}

const Spreadsheet = ({ className }: SpreadsheetProps) => {
  const spreadRef = useRef<GC.Spread.Sheets.Workbook | null>(null);

  // TODO: Remove placeholder data
  const data = [
    {
      Name: "Apple",
      Category: "Fruit",
      Price: 1,
      "Shopping Place": "Wal-Mart",
    },
    {
      Name: "Potato",
      Category: "Fruit",
      Price: 2.01,
      "Shopping Place": "Other",
    },
    {
      Name: "Tomato",
      Category: "Vegetable",
      Price: 3.21,
      "Shopping Place": "Other",
    },
    {
      Name: "Sandwich",
      Category: "Food",
      Price: 2,
      "Shopping Place": "Wal-Mart",
    },
    {
      Name: "Hamburger",
      Category: "Food",
      Price: 2,
      "Shopping Place": "Wal-Mart",
    },
    {
      Name: "Grape",
      Category: "Fruit",
      Price: 4,
      "Shopping Place": "Sun Store",
    },
  ];

  // TODO: SpreadJS licensing for spreadJS, Designer component and other SpreadJS components
  // Trial is only valid for local deployment, not for production.
  // Licensing SpreadJS
  // const SpreadJSKey = "xxx"; // Enter a valid license key.
  // GC.Spread.Sheets.LicenseKey = SpreadJSKey;

  const handleWorkbookInit = (spread: GC.Spread.Sheets.Workbook) => {
    spreadRef.current = spread;
  };

  return (
    <div className={className}>
      <SpreadSheets workbookInitialized={handleWorkbookInit}>
        <Worksheet name="Rollup" />
        <Worksheet name="Example" dataSource={data}>
          <Column dataField="Name" width={300}></Column>
          <Column dataField="Category"></Column>
          <Column dataField="Price" formatter="$#.00"></Column>
          <Column dataField="Shopping Place"></Column>
        </Worksheet>
      </SpreadSheets>
    </div>
  );
};

export default Spreadsheet;
